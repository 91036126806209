import React from 'react';
import { VeltNotificationsPanelWireframe } from '@veltdev/react';
import styled, { createGlobalStyle } from 'styled-components';
import { Box, Flex } from '../../theme/components';

export const Underline = styled.span`
  text-decoration: underline;
`;

export const NotificationsWireframe = () => (
  <VeltNotificationsPanelWireframe variant="drawer-content">
    <Flex
      marginBottom="16px"
      justifyContent="space-between"
      alignItems="center"
    >
      <VeltNotificationsPanelWireframe.Header />
    </Flex>
    <VeltNotificationsPanelWireframe.Content>
      <VeltNotificationsPanelWireframe.Content.ForYou />
      <VeltNotificationsPanelWireframe.Content.All />
    </VeltNotificationsPanelWireframe.Content>
  </VeltNotificationsPanelWireframe>
);

export const NotificationsItemWireframe = () => (
  <VeltNotificationsPanelWireframe.Content.List.Item>
    <Flex width="100%" overflow="hidden">
      <Box position="relative" width="40px" flexShrink={0} marginTop="4px">
        <VeltNotificationsPanelWireframe.Content.List.Item.Avatar />
        <VeltNotificationsPanelWireframe.Content.List.Item.Unread />
      </Box>
      <Box width="320px">
        <VeltNotificationsPanelWireframe.Content.List.Item.Headline /> in{' '}
        <Underline>
          <VeltNotificationsPanelWireframe.Content.List.Item.FileName />
        </Underline>
        <VeltNotificationsPanelWireframe.Content.List.Item.Time />
        <VeltNotificationsPanelWireframe.Content.List.Item.Body />
      </Box>
    </Flex>
  </VeltNotificationsPanelWireframe.Content.List.Item>
);

export const NotificationsStyles = createGlobalStyle`
 .app-notifications-drawer .velt-notifications-panel {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
    width: 100% !important;
    padding: 0 !important;
    max-height: none !important;
  }

  .velt-notifications-panel--header {
    padding: 0 !important;
    width: 150px !important;
  }

  .velt-notifications-panel--tabs-container {
    border-radius: ${({ theme }) => theme.borderRadius.default}px !important;
    border: none !important;
    background: ${({ theme }) => theme.colors.background.layout} !important;
  }

  .velt-notifications-panel--tab {
    border-radius: ${({ theme }) => theme.borderRadius.default}px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    padding: 4px 8px !important;
    color: ${({ theme }) => theme.colors.text.default} !important;
  }

  .velt-notifications-panel-content {
    max-height: none !important
  };

  .velt-notifications-panel--content-all-list-item {
    margin: 0 !important;
  }

  .velt-notifications-panel--content-all-list-item-label {
    color: ${({ theme }) => theme.colors.text.default} !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: 24px !important;
    letter-spacing: 3px !important;
  }

  .velt-notification-file-name {
    color: ${({ theme }) => theme.colors.text.secondary} !important;
  }

  .v-notification-timestamp {
    color: ${({ theme }) => theme.colors.text.secondary} !important;
  }


  .all-notifications-view-all-btn {
    color: ${({ theme }) => theme.colors.link} !important;
  }

  .velt-notifications-panel--list-item-body {
    overflow-x: hidden !important;
    text-overflow: ellipsis !important;
  }

  .velt-notifications-panel--list-item {
    width: 100% !important;
    overflow-x: hidden !important;
  }

  .velt-notifications-panel--list-item-headline {
    display: inline !important;
  }

  .velt-notifications-panel--list-item-unread {
    position: absolute !important;
    top: -4px !important;
    right: 12px !important;
  }

  .velt-notifications-panel--list-item-body {
    margin-top: 8px !important;
  }
`;
