import React from 'react';
import { Flex, Spinner } from '../../theme/components';
import { FullPageCentered } from '../../theme/styles/styles';
import { useTrackPageViews } from './useTrackPageViews';
import { useUpdateIntercom } from './useUpdateIntercom';
import { useSetupFeatureFlag } from './useSetupFeatureFlag';
import { usePageTitle } from '../../hooks/usePageTitle';
import { SideNavigationContainer } from './components/SideNavigation/SideNavigationContainer';
import { ErrorBoundary } from '../ErrorBoundary';
import { useCurrentProperty } from '../../hooks/useCurrentProperty';
import { AccessLevel } from '../../__generated__/graphql';
import { NotificationsDrawer } from '../NotificationsDrawer';

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  const currentProperty = useCurrentProperty();

  usePageTitle();
  useTrackPageViews();
  useSetupFeatureFlag();
  useUpdateIntercom();

  const ready =
    !!currentProperty && currentProperty.accessLevel !== AccessLevel.None;

  return (
    <Flex minHeight="100vh" overflow="hidden">
      {ready ? (
        <>
          <SideNavigationContainer />
          <Flex
            position="relative"
            flexDirection="column"
            borderLeft="1px solid rgb(232, 232, 232)"
            flex={1}
            height="100vh"
          >
            <NotificationsDrawer />
            <ErrorBoundary>{children}</ErrorBoundary>
          </Flex>
        </>
      ) : (
        <FullPageCentered>
          <Spinner />
        </FullPageCentered>
      )}
    </Flex>
  );
};
