import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { VeltConfirmDialogWireframe } from '@veltdev/react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Typography } from 'antd';
import { Box, Flex } from '../../theme/components';

const StyledExclamationIcon = styled(ExclamationCircleOutlined)`
  font-size: 18px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.warning.default};
`;

const DialogTitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const ButtonContainer = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px;
`;

export const DeleteThreadWireframe = () => (
  <VeltConfirmDialogWireframe>
    <Flex>
      <Box mr={16}>
        <StyledExclamationIcon />
      </Box>
      <Flex flexDirection="column">
        <DialogTitle>Delete Comment Thread?</DialogTitle>
        <Typography.Text>
          Are you sure you want to delete this comment thread?
        </Typography.Text>
      </Flex>
    </Flex>
    <ButtonContainer>
      <VeltConfirmDialogWireframe.RejectButton>
        <Button type="default">Cancel</Button>
      </VeltConfirmDialogWireframe.RejectButton>
      <VeltConfirmDialogWireframe.ApproveButton>
        <Button type="primary" danger>
          Delete
        </Button>
      </VeltConfirmDialogWireframe.ApproveButton>
    </ButtonContainer>
  </VeltConfirmDialogWireframe>
);

export const DeleteThreadStyles = createGlobalStyle`
  .mat-mdc-dialog-surface {
    border-radius: 4px !important;
  }

  .mat-mdc-dialog-component-host {
    padding: 24px !important;
  }
  app-confirm-dialog-reject {
    flex: 0 !important;
  }
  app-confirm-dialog-approve {
    flex: 0 !important;
  }

  velt-confirm-dialog-approve-button-wireframe .ant-btn-dangerous {
    box-shadow: none !important;
`;
