// @ts-strict-ignore
// Uncomment this line and open devtools console
// to see why each component rendered. Helpful
// for perf debugging:
// import './utils/whyDidYouRender';

import 'core-js/stable';
import 'whatwg-fetch';
import './tracking/google';
import './firebase';
import './utils/telemetry';

import * as Sentry from '@sentry/browser';
import React from 'react';
import { ConfigProvider, App as AntdApp } from 'antd';
import { ThemeProvider } from 'styled-components';

import moment from 'moment';
import enUS from 'antd/locale/en_US';

import { ApolloProvider } from '@apollo/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import LogRocket from 'logrocket';
import App from './App';

import { notEmpty } from './utils';
import { ErrorBoundary } from './components/ErrorBoundary';
import { launchDarklyId } from './config';
import { AuthProvider } from './contexts/AuthContext';
import { client } from './graphqlClient';
import { transactionId } from './constants';
import { VeltProvider } from './contexts/VeltProvider';

import theme from './theme';
import antTheme, { configureGlobalMessage } from './theme/themes/antTheme';

if (notEmpty(SENTRY_DSN)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: RELEASE,
    beforeSend(event) {
      const updatedEvent = { ...event };
      if (!updatedEvent.extra) updatedEvent.extra = {};
      updatedEvent.extra.transaction_id = transactionId;

      const logRocketSession = LogRocket.sessionURL || '';
      updatedEvent.extra.LogRocket = logRocketSession;

      return updatedEvent;
    }
  });
}

/* Remove console logs in production */

moment.updateLocale('en', { week: { dow: 1 } }); // start week from Monday
moment.updateLocale('en', {
  relativeTime: {
    future: 'A few seconds ago',
    past: '%s ago',
    s: 'A few seconds',
    ss: '%d seconds',
    m: 'A minute',
    mm: '%d minutes',
    h: 'An hour',
    hh: '%d hours',
    d: 'A day',
    dd: '%d days',
    M: 'A month',
    MM: '%d months',
    y: 'A year',
    yy: '%d years'
  }
});

configureGlobalMessage();

const container = document.getElementById('app');
const root = createRoot(container);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyId,
    context: {
      kind: 'user',
      key: 'gallus-anonymus'
    },
    options: { sendEventsOnlyForVariation: true },
    reactOptions: { useCamelCaseFlagKeys: false }
  });

  root.render(
    <ApolloProvider client={client}>
      <LDProvider>
        <ConfigProvider locale={enUS} theme={antTheme}>
          <ThemeProvider theme={theme}>
            <AntdApp>
              <VeltProvider>
                <ErrorBoundary>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </ErrorBoundary>
              </VeltProvider>
            </AntdApp>
          </ThemeProvider>
        </ConfigProvider>
      </LDProvider>
    </ApolloProvider>
  );
})();
