import { useLDClient } from 'launchdarkly-react-client-sdk';

export enum FeatureFlags {
  Restrictions = 'enabling-restrictions-product',
  SplitTests = 'split-tests',
  ResetManualPrices = 'reset-manual-prices',
  NewQuickViewHideOptimalPrice = 'new-quick-view-hide-optimal-price',
  NewQuickViewHideOptimalPriceCurve = 'new-quick-view-hide-optimal-price-curve',
  NewQuickViewOptimalRecommendedMode = 'new-quick-view-optimal-recommended-mode',
  NewQuickViewOccupancyForecast = 'new-quick-view-occupancy-forecast',
  NewRestrictionsFramework = 'new-restrictions-framework',
  RatePlansPageRestrictionClassification = 'rate-plans-page-restriction-classification',
  InsightsExplorerHyperlinks = 'insights-explorer-hyperlinks',
  AIPriceLabels = 'ai-price-labels',
  FixedExchangeRatesPage = 'fixed-fx-rates-page',
  NewGroupQuotation = 'new-group-quotation',
  ManagementByException = 'management-by-exception',
  CalendarEventsInPlanning = 'calendar-events-in-planning',
  ForecastEvolutionButton = 'forecast-evolution-button',
  GroupsQuotationConfiguration = 'groups-quotation-configuration',
  OmniEmbedPOC = 'omni-embed-poc',
  OmniEmbedApplication = 'omni-application-embed',
  QuickViewRestrictionsTab = 'quick-view-restrictions-tab',
  GroupProfitability = 'group-profitability',
  CollaborationTool = 'use-collaboration-tool'
}

export enum Entitlements {
  PaceAnalytics = 'entitle-PaceAnalytics',
  AnalyticsExplore = 'entitle-AnalyticsExplore'
}

type VariationsType = FeatureFlags | Entitlements;

const useFlagClient = () => {
  const ldClient = useLDClient();

  return {
    variation: (name: VariationsType, defaultValue: unknown) => {
      const value = ldClient?.variation(name, defaultValue);

      return value;
    },
    allFlags: () => {
      const flags = ldClient?.allFlags();
      return flags;
    }
  };
};

export const useFlag = (name: VariationsType) => {
  const { variation } = useFlagClient();

  if (name === FeatureFlags.QuickViewRestrictionsTab) {
    return true;
  }

  return variation(name, false);
};
