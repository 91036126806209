import { useEffect } from 'react';
import { useVeltClient } from '@veltdev/react';
import { gql } from '@apollo/client';

import { useCurrentUser } from './useCurrentUser';
import { useGetCollaborationTokenMutation } from '../__generated__/graphql';

interface Props {
  currentUser: NonNullable<ReturnType<typeof useCurrentUser>>;
}

gql`
  mutation GetCollaborationToken {
    getCollaborationToken
  }
`;

export function useVeltIdentify({ currentUser }: Props) {
  const [mutate] = useGetCollaborationTokenMutation();

  const { client } = useVeltClient();

  useEffect(() => {
    mutate()
      .then(({ data }) => {
        if (!data) {
          throw new Error('No data returned from getCollaborationToken');
        }

        if (client) {
          const user = {
            userId: currentUser.id,
            email: currentUser.email,
            name: currentUser.email.split('@')[0],
            organizationId: currentUser.propertyGroupId
          };

          return client.identify(user, {
            forceReset: true,
            authToken: data.getCollaborationToken
          });
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Unable to set up collaboration');
      });
  }, [client, currentUser, mutate]);
}
