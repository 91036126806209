import {
  StrategicRestrictionType,
  TacticalRestrictionType,
  CalendarEntryType
} from '../../__generated__/graphql';
import {
  ColorTeal40,
  ColorPurple70,
  ColorTeal30,
  ColorYellow30,
  ColorYellow60,
  ColorYellow80,
  ColorYellow90,
  ColorGreen5,
  ColorGreen10,
  ColorGreen30,
  ColorGreen50,
  ColorGreen70,
  ColorGreen80,
  ColorGreen90,
  ColorRed30,
  ColorRed50,
  ColorRed60,
  ColorRed70,
  ColorGrey20,
  ColorGrey30,
  ColorGrey50,
  ColorGrey60,
  ColorGrey70,
  ColorGrey80,
  ColorGrey100,
  ColorPurple50,
  ColorPurple20,
  ColorBlue100,
  ColorBlue90,
  ColorBlue80,
  ColorBlue70,
  ColorBlue60,
  ColorBlue30,
  ColorBlue20,
  ColorBlue10,
  ColorTeal50,
  ColorWhiteDefault,
  ColorBlackDefault,
  ColorBrightRed10,
  ColorSeaGreen10,
  ColorPowderBlue50,
  ColorCadetBlue60,
  ColorGold20,
  ColorFireBrick40,
  ColorBrightRed30,
  ColorSeaGreen50,
  ColorFireBrick50,
  ColorSeaGreen20,
  ColorSteelBlue20,
  ColorOrange20
} from '../tokens/colors';

export const customTheme = {
  restrictions: {
    [StrategicRestrictionType.ClosedToStay]: ColorTeal40,
    [StrategicRestrictionType.ClosedToArrival]: '#8a86f6',
    [StrategicRestrictionType.ClosedToDeparture]: ColorTeal30,
    [TacticalRestrictionType.CloseToStay]: ColorTeal40,
    [TacticalRestrictionType.CloseToArrival]: '#8a86f6',
    [TacticalRestrictionType.CloseToDeparture]: ColorTeal30
  },
  calendar: {
    [CalendarEntryType.DefaultPriceSetting]: ColorGrey50,
    [CalendarEntryType.EventPriceSetting]: '#FFE2BA',
    [CalendarEntryType.SeasonalPriceSetting]: '#B3D2EF',
    [CalendarEntryType.PropertyClosure]: '#FFCFBA',
    [CalendarEntryType.Note]: '#E7B3EF'
  } as Record<CalendarEntryType, string>,
  optimalPricingTable: {
    darkRed: ColorRed70,
    lightGreen: ColorGreen80,
    lightRed: '#F7CFD3',
    darkGreen: '#6AB4AC',
    alertBg: '#f7f7f8'
  },
  restrictionsPricingTable: {
    skyblue: ColorBlue70,
    mint: ColorGreen90
  },
  aiPrice: {
    lightTeal: '#9cd3d2',
    darkTeal: '#35b0af',
    lightPlum: '#ca9ab7',
    darkPlum: '#a65686'
  },
  aiPriceLabel: {
    lightPurple: '#8a86f6'
  },
  tag: {
    active: ColorSeaGreen10,
    notActive: ColorBrightRed10
  },
  groupsChartColors: {
    OTB: ColorPowderBlue50,
    GroupRequest: ColorCadetBlue60,
    TransientFCST: ColorGold20,
    Displacement: ColorFireBrick40,
    DisplacementShoulderNights: ColorBrightRed30,
    LY: ColorSeaGreen50,
    BookableCapacity: ColorFireBrick50
  },
  opportunityIndicatorColors: {
    high: ColorSeaGreen20,
    medium: ColorOrange20,
    low: ColorSteelBlue20
  },
  velt: {
    openStatus: '#F0F5FF',
    inProgressStatus: 'rgba(255, 205, 46, 0.12)',
    resolvedStatus: '#E8F8F5',
    statusBackground: 'rgb(240, 245, 255)',
    resolvedStatusColor: '#00C48C'
  }
};

export const plainColors = {
  yellow30: ColorYellow30,
  yellow60: ColorYellow60,
  yellow80: ColorYellow80,
  yellow90: ColorYellow90,
  green5: ColorGreen5,
  green10: ColorGreen10,
  green30: ColorGreen30,
  green50: ColorGreen50,
  green70: ColorGreen70,
  green80: ColorGreen80,
  green90: ColorGreen90,
  red30: ColorRed30,
  red50: ColorRed50,
  red60: ColorRed60,
  red70: ColorRed70,
  grey20: ColorGrey20,
  grey30: ColorGrey30,
  grey50: ColorGrey50,
  grey60: ColorGrey60,
  grey70: ColorGrey70,
  grey80: ColorGrey80,
  grey100: ColorGrey100,
  purple70: ColorPurple70,
  purple50: ColorPurple50,
  purple20: ColorPurple20,
  blue100: ColorBlue100,
  blue90: ColorBlue90,
  blue80: ColorBlue80,
  blue70: ColorBlue70,
  blue60: ColorBlue60,
  blue30: ColorBlue30,
  blue20: ColorBlue20,
  blue10: ColorBlue10,
  teal50: ColorTeal50,
  whiteDefault: ColorWhiteDefault,
  blackDefault: ColorBlackDefault
};
