import React, { useEffect } from 'react';
import LogRocket from 'logrocket';

import { notEmpty } from './utils';
import { initialiseLogRocket } from './tracking/logRocket';
import { useCurrentUser } from './hooks/useCurrentUser';
import { useVeltIdentify } from './hooks/useVeltIdentify';

interface Props {
  currentUser: NonNullable<ReturnType<typeof useCurrentUser>>;
}

export const UserIdentification: React.FC<Props> = ({ currentUser }) => {
  useVeltIdentify({ currentUser });

  useEffect(() => {
    const userPropertiesForTracking = {
      email: currentUser.email,
      userType: currentUser.role,
      propertyGroupName: currentUser.propertyGroupName,
      propertyGroupID: currentUser.propertyGroupId
    };

    /* Hotjar */
    if (window.hj) {
      const isPaceUser =
        currentUser.email.includes('@pacerevenue.com') ||
        currentUser.email.includes('@paceup.com');

      if (!isPaceUser) {
        window.hj('identify', currentUser.id, userPropertiesForTracking);
        window.hj('event', 'start_session');
      }
    }

    /* LogRocket */
    // Before calling LogRocket.init, sessionURL is `Must call LogRocket.init() before sessionURL becomes available.`
    // After calling LogRocket.init, it might take some time for sessionURL to be populated. For this time, it is probably `null` (see https://docs.logrocket.com/reference/get-session-url)
    if (
      !LogRocket.sessionURL?.includes('http') &&
      notEmpty(LOGROCKET_KEY) &&
      !LOGROCKET_IGNORE_ACCOUNTS?.split(',').includes(currentUser.email)
    ) {
      initialiseLogRocket();
    }

    /* Heap */
    if (window.heap) {
      window.heap.identify(currentUser.id);
      window.heap.addUserProperties(userPropertiesForTracking);
    }

    /* Canny */
    if (window.Canny && CANNY_APP_ID) {
      window.Canny('identify', {
        appID: CANNY_APP_ID,
        user: {
          email: currentUser.email,
          id: currentUser.id,
          name: currentUser.email
        }
      });
    }
  }, [currentUser]);

  return null;
};
